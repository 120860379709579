<template lang="pug">
.template-chooser-listing-page
  Skeleton(v-if="isLoading")
  template(v-else)
    .template-chooser-listing-page__container.row(v-if="templates && templates.length")
      template(v-for="(template, index) in templates")
        TemplateBox.col-12.col-lg-6.col-xl-4.mb-6(
          @observable="addObservable($event.$el)"
          :title="!isUseCaseListing && !isResellerListing ? getTitle(template.useCase) : null"
          :template="template"
          :key="template._id"
          heap-event="new-selector-campaign-listing"
          :themeKit="themeKit"
          @hide="hide(template)"
          @show="show(template)"
          :baseThemeKitName="baseThemeKitName"
        )
    ListEmptyState(
      v-else
      :image="require('@/assets/admin/svg/monk_magnifying.svg')"
      :title="$t('templateChooser.emptyStateBaseTitle')"
    )
</template>

<script>
  import { mapGetters, mapState, mapActions } from 'vuex';
  import observableCollectionMixin from '@/mixins/observableCollection';
  import templateBoxParent from '../mixins/templateBoxParent';
  import filterHelperMixin from '../mixins/filterHelper';

  export default {
    components: {
      ListEmptyState: () => import('@/components/TemplateChooser/components/ListEmptyState.vue'),
      Skeleton: () => import('@/components/TemplateChooser/components/Skeleton.vue'),
      TemplateBox: () => import('@/components/TemplateChooser/components/TemplateBox.vue'),
      FilterBanner: () => import('@/components/TemplateChooser/components/FilterBanner.vue'),
    },
    mixins: [observableCollectionMixin, templateBoxParent, filterHelperMixin],
    beforeRouteEnter(to, from, next) {
      next(() => {
        window.om.store.dispatch('templateChooser/refetchWithFilterReset');
      });
    },
    props: {
      rootMargin: { type: String },
    },
    computed: {
      ...mapState(['accountType']),
      ...mapState('useCase', ['useCaseMap', 'useCaseLoading']),
      ...mapState('templateChooser', ['filteredTemplates', 'resellerTemplates']),
      ...mapGetters('templateChooser', [
        'loading',
        'mergedThemes',
        'userThemes',
        'templatesByGoals',
      ]),
      ...mapGetters('useCase', ['localizedUseCase', 'extraDetailsByUseCaseId']),
      isLoading() {
        return this.loading || this.useCaseLoading;
      },
      isBaseThemesRoute() {
        return this.$route.name === 'themes-templates';
      },
      isUserThemesRoute() {
        return this.$route.name === 'your-themes-templates';
      },
      isCustoms() {
        return this.$route.name === 'custom-templates';
      },
      isSeasonal() {
        return this.$route.name.includes('seasonal');
      },
      isOtherListing() {
        return this.$route.name === 'chooser-other-listing';
      },
      isUseCaseListing() {
        return this.$route.name === 'chooser-use-case';
      },
      isResellerListing() {
        return this.$route.name === 'reseller-templates';
      },
      themeKit() {
        const theme = this.getTheme();
        const themeKit = theme?.themeKit || null;
        if (theme?.sourceTheme && themeKit) {
          themeKit.source = theme?.sourceTheme;
        }
        return themeKit;
      },
      baseThemeKitName() {
        if (this.isSeasonal) {
          const key = `templateFilter.categories.${this.$route.params.slug}`;

          return this.$te(key) ? this.$t(key) : null;
        }

        const theme = this.getTheme();
        if (theme?.sourceTheme) {
          const baseTheme = this.userThemes.find(({ _id }) => _id === theme.sourceTheme);
          return baseTheme?.name;
        }
        return theme?.name;
      },
      templates() {
        if (this.isResellerListing) {
          return this.resellerTemplates;
        }

        if (this.isUseCaseListing) {
          return this.extraDetailsByUseCaseId(this.getIdFromSlug());
        }

        if (this.isOtherListing) {
          return Object.values(this.templatesByGoals[this.$route.params.slug]).flat();
        }

        if (this.isSeasonal) {
          return this.filteredTemplates.byCategory[this.getIdFromSlug()];
        }

        if (this.isBaseThemesRoute) {
          const templates = this.filteredTemplates.byTheme[this.getIdFromSlug()];
          return templates ?? [];
        }

        if (this.isUserThemesRoute) {
          const selectedTheme = this.userThemes.find(({ _id }) => _id === this.getIdFromSlug());
          return selectedTheme.templates;
        }

        return [];
      },
    },
    methods: {
      ...mapActions('templateChooser', ['refetchWithFilterReset']),
      getIdFromSlug() {
        return this.$route.params?.slug?.split('-')?.splice(-1)?.[0] ?? 'Custom';
      },
      getTheme() {
        const id = this.getIdFromSlug();

        return this.mergedThemes.find(({ _id }) => _id === id);
      },
      hide(template) {
        template.hidden = true;
      },
      show(template) {
        template.hidden = false;
      },
      getPossibleTemplates() {
        return this.templates;
      },
      getTemplates(items, searchResults, sortOrder) {
        if (this.search) {
          return searchResults
            ?.map?.(({ _id: relevantId }) => {
              return items?.find?.(({ _id }) => relevantId === _id);
            })
            .filter((v) => !!v);
        }
        const templates = items ?? [];

        if (sortOrder) {
          return sortOrder.map((id) => templates.find(({ _id }) => _id === id)).filter(Boolean);
        }

        return templates;
      },
      getTitle(useCaseId) {
        return this.localizedUseCase(useCaseId)?.name;
      },
    },
  };
</script>

<style lang="sass">
  .template-chooser-listing-page
    .template-chooser-page-loading-skeleton
      .loading-row
        margin-bottom: 3.5rem !important
</style>
